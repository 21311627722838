<template>
  <v-app style="background-color: #fafafa">
    <v-main fluid tag="section" class="pt-0">
      <LogoTopBar ref="topBar" />
      <section class="pt-8">
        <div class="d-flex justify-center align-center">
          <div v-if="isVideo" class="mt-16">
            <div class="text-subtitle-2">
              {{ capturedDate }} at
              {{ capturedTime }}
            </div>
            <div class="text-subtitle-1 pb-2 font-weight-medium">
              Captured from the {{ fromAppName }} app.
            </div>
            <v-card>
              <v-card-text>
                <div
                  v-if="!$vuetify.breakpoint.mobile"
                  class="d-flex justify-center"
                >
                  <video
                    ref="videoElement"
                    controls
                    height="450"
                    width="800"
                  ></video>
                </div>
                <div v-else>
                  <video
                    ref="videoElement"
                    controls
                    height="170"
                    width="300"
                  ></video>
                </div>
              </v-card-text>
            </v-card>
            <div class="d-flex pt-2">
              <v-spacer></v-spacer>
              <div class="text-subtitle-2">Captured by {{ capturedBy }}</div>
            </div>
          </div>
          <div v-else-if="isImage" class="mt-16">
            <div class="text-subtitle-2">
              {{ capturedDate }} at
              {{ capturedTime }}
            </div>
            <div class="text-subtitle-1 pb-2 font-weight-medium">
              Captured from the {{ fromAppName }} app.
            </div>
            <v-card>
              <v-card-text>
                <div
                  v-if="!$vuetify.breakpoint.mobile"
                  class="d-flex justify-center"
                >
                  <img
                    ref="imageElement"
                    :src="captureURL"
                    :style="{ 'max-height': '450px', 'max-width': '800px' }"
                  />
                </div>
                <div v-else>
                  <img
                    ref="imageElement"
                    :src="captureURL"
                    :style="{ 'max-height': '170px', 'max-width': '300px' }"
                  />
                </div>
              </v-card-text>
            </v-card>
            <div class="d-flex pt-2">
              <v-spacer></v-spacer>
              <div class="text-subtitle-2">Captured by {{ capturedBy }}</div>
            </div>
          </div>
        </div>
      </section>
    </v-main>
  </v-app>
</template>

<script>
import {} from "@mdi/js";
import LogoTopBar from "@/components/app/LogoTopBar";
import { axiosWithNoAuth } from "@/plugins/axios";
import moment from "moment";

const APIURL = process.env.VUE_APP_API_URL;
const CAPTURES_BASE_URL = process.env.VUE_APP_CAPTURES_S3_BASE_URL;

export default {
  name: "Capture",
  components: {
    LogoTopBar,
  },
  data() {
    return {
      captureData: {},
    };
  },
  methods: {
    async getCaptureData() {
      const currentURL = window.location.href;
      const segments = currentURL.split("/");
      const file_id = segments[segments.length - 1];
      const {
        data: { results },
      } = await axiosWithNoAuth.get(`${APIURL}/captures/${file_id}`);
      this.captureData = results;
    },
  },
  computed: {
    fromAppName() {
      if (this.captureData.app === "Docs") {
        return "UtiliSync Docs";
      }
      if (this.captureData.app === "811") {
        return "UtiliSync811";
      }
      if (this.captureData.app === "Admin") {
        return "UtiliSync Admin";
      }
      return "";
    },
    capturedBy() {
      return this.captureData.f_name + " " + this.captureData.l_name;
    },
    capturedDate() {
      const userDateTime = moment(this.captureData.created_on).utcOffset(
        moment().utcOffset()
      );
      return userDateTime.format("MMM D, YYYY");
    },
    capturedTime() {
      const userDateTime = moment(this.captureData.created_on).utcOffset(
        moment().utcOffset()
      );
      return userDateTime.format("h:mm A");
    },
    captureURL() {
      return `${CAPTURES_BASE_URL}/${this.captureData.user_group_id}/${this.captureData.file_id}.${this.captureData.file_extension}`;
    },
    isVideo() {
      return (
        this.captureData.file_extension === "webm" ||
        this.captureData.file_extension === "mp4" ||
        this.captureData.file_extension === "mov"
      );
    },
    isImage() {
      return (
        this.captureData.file_extension === "jpg" ||
        this.captureData.file_extension === "png" ||
        this.captureData.file_extension === "heic"
      );
    },
  },
  beforeMount() {
    this.getCaptureData();
  },
  mounted() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "hidden";
  },
  beforeDestroy() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
  },
  watch: {
    captureData: {
      deep: true,
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.isVideo) {
            this.$refs.videoElement.src = this.captureURL;
          }
        });
      },
    },
  },
};
</script>
